import React, { useState } from 'react';
import './Table.css';
import { ReactComponent as SeeIcon } from '../../svg/SeeIcon.svg';
import { ReactComponent as EditIcon } from '../../svg/EditIcon.svg';
import { ReactComponent as DownloadIcon } from '../../svg/DownloadIcon.svg';
import { ReactComponent as ActiveCheck } from '../../svg/ActiveCheck.svg';
import { ReactComponent as UploadIcon } from '../../svg/UploadIcon.svg';
import {useTranslation} from "react-i18next";

function Table({ data, download, edit, see, upload, onChangeOrder, loading, ignoreCol }) {
    const {t} = useTranslation("common");
    console.log("Table data", data);
    
    const [order, setOrder] = useState({ column: null, direction: null });

    const realcolumn = (column) => {
        if (column === 'useremail') return 'emaila';
        if (column === 'edit') return null;
        if (column === 'dia') return null;
        if (column === 'hora') return null;
        if (column === 'ko') return null;
        if (column === 'ok') return null;
        if (column === 'total') return null;
        if (column === 'see') return null;
        if (column === 'download') return null;
        if (column === 'upload') return null;
        return column;
    }

    const langcolumn = (column) => {
        switch (column) {
            case 'group':
            return t('app.reports.group');
            case 'personid':
            return t('app.reports.personid');
            case 'useremail':
            return t('app.reports.useremail');
            case 'user':
            return t('app.reports.user');
            case 'gurasoak':
            return t('app.reports.gurasoak');
            case 'gender':
            return t('app.reports.gender');
            case 'jaiotza':
            return t('app.reports.jaiotza');
            case 'Download':
            return t('app.reports.download');
            case 'Upload':
            return t('app.reports.upload');
            case 'See':
            return t('app.reports.see');
            case 'Edit':
            return t('app.reports.edit');
            case 'herria':
            return t('app.reports.herria');
            case 'roll':
            return t('app.reports.roll');
            case 'emaila':
            return t('app.reports.emaila');
            case 'erakundea':
            return t('app.reports.erakundea');
            case 'countReports':
            return t('app.reports.countReports');
            case 'countUploads':
            return t('app.reports.countUploads');
            case 'zenbat':
            return t('app.reports.limitUsers');
            case 'edad':
            return t('app.reports.edad');
            default:
            return column;
        }
        };

    const ignoreColumn = (column) => {
        if (column === 'error') return true;
        if (column === 'calculoError') return true;
        if (column === 'id') return true;
        if (column === 'status') return true;
        if (column === 'calculoSN') return true;
        if (column === 'logo') return true;
        if (column === ignoreCol) return true;
        if (column === 'filemed') return true;
        if (column === 'filegen') return true;
        if (column === 'erakundea') return true;
        if (column === 'footerpdf') return true;
        if (column === 'roll') return true;
        if (data[0].roll == 'user' && column === 'cif') return true;
        if (data[0].roll == 'user' && column === 'erakundea') return true;
        if (data[0].roll == 'user' && column === 'zenbat') return true;
        if (data[0].roll == 'user' && column === 'logo') return true;
        if (data[0].roll == 'user' && column === 'footerpdf') return true;
        if (data[0].roll == 'user' && column === 'contacto') return true;
        if (data[0].roll == 'user' && column === 'cp') return true;
        if (data[0].roll == 'user' && column === 'herria') return true;
        if (data[0].roll == 'user' && column === 'provincia') return true;
        if (data[0].roll == 'user' && column === 'telefono') return true;
        if (data[0].roll == 'user' && column === 'helbidea') return true;
        if (data[0].roll == 'user' && column === 'pais') return true;


        return false;
    }

    if (!data || !data.length) return <p>{t('app.reports.noresults')}</p>;
    const columns = Object.keys(data[0]).map((key) => ({
        Header: key,
        accessor: key,
    }));

    const handleDownload = (row) => {
        download(row);
    };

    const handleEdit = (row) => {
        edit(row);
    };

    const handleSee = (row) => {
        see(row);
    };

    const handleUpload = (row) => {
        upload(row);
    }

    const setIcon = (column) => {
        if (column === 'edit') return EditIcon;
        if (column === 'see') return SeeIcon;
        if (column === 'download') return DownloadIcon;
        if (column === 'upload') return UploadIcon;
        return null;
    };

    const setOnclick = (column) => {
        if (column === 'edit') return handleEdit;
        if (column === 'see') return handleSee;
        if (column === 'download') return handleDownload;
        if (column === 'upload') return handleUpload;
        return null;
    };

    const handleOrder = (column) => {
        let direction = 'ASC';
        if (order.column === column && order.direction === 'ASC') {
            direction = 'DESC';
        } else if (order.column === column && order.direction === 'DESC') {
            direction = null;
        }
        //cambiar la columna por el nombre de la columna de la base de datos
        column = realcolumn(column);
        if(!column) return;

        setOrder({ column, direction });
        onChangeOrder({ column, direction });
    };

    if (download)
    columns.push({
        Header: 'Download',
        accessor: 'download'
    });

    if (upload)
    columns.push({
        Header: 'Upload',
        accessor: 'upload'
    });

    if (edit)
    columns.push({
        Header: 'Edit',
        accessor: 'edit'
    });

    if (see)
    columns.push({
        Header: 'See',
        accessor: 'see'
    });

    return (
        <div className={`table-box ${loading ? '' : 'loaded'}`}>
            <table className="table table-bordered dashboard-table">
                <thead>
                    <tr className='table-secondary table-borderless border-white'>
                    {columns.map((column) => {
                        const isOrderColumn = order.column === column.accessor;
                        const isAsc = order.direction === 'ASC';
                        const orderClass = isOrderColumn ? (isAsc ? 'ASC' : 'DESC') : '';
                        if (ignoreColumn(column.accessor)) return null;
                        return (
                            <th
                                key={column.accessor}
                                className={orderClass}
                                onClick={() => handleOrder(column.accessor)}
                            >
                                {langcolumn(column.Header)}
                            </th>
                        );
                    })}
                    </tr>
                </thead>
                <tbody>
                    {data.map((row) => {
                        // compruebo si pintar la columna de rojo si hubo errores, depende de estatus ko o ok
                        //const error = row.status === 'ko' || row.calculoSN === 'ko' ? 'table-red-color' : '';
                        // si la columna es activo pinto un checkbox
                        if(row.activo){
                            row.activo = row.activo == "1" ? <ActiveCheck/> : '';
                        }

                        let classToday = '';

                        if(row.dia) {
                            const date = new Date(row.dia);
                        
                            const timeString = row.hora;
                            const timeParts = timeString.split(' '); // Separa la hora del AM/PM
                            const hoursMinutes = timeParts[0].split(':'); // Separa las horas y los minutos
                            let hours = parseInt(hoursMinutes[0], 10);
                            const minutes = parseInt(hoursMinutes[1], 10);
                            const period = timeParts[1]; // AM o PM
                        
                            // Convertir a formato de 24 horas
                            if (period === 'PM' && hours !== 12) {
                                hours += 12;
                            } else if (period === 'AM' && hours === 12) {
                                hours = 0;
                            }
                        
                            // Establecer la fecha y hora
                            date.setHours(hours, minutes, 0); // segundos establecidos a 0

                            const now = new Date();
                            now.setHours(now.getHours() - 2);
                            const difference = now - date;  // Diferencia en milisegundos
                        
                            if(difference >= 0 && difference <= 60000) { 
                                classToday = 'today';
                            }
                        }                        
                        
                        return (
                            <tr className={classToday} key={row.id}>
                                {columns.map((column) => {
                                    if (ignoreColumn(column.accessor)) return null;
                                    let Icon = setIcon(column.accessor);
                                    let onClick = setOnclick(column.accessor);
                                    return (
                                    
                                    <td
                                        role={onClick ? 'button' : null}
                                        onClick={() => onClick ? onClick(row) : null}
                                        key={column.accessor}
                                    >
                                        {Icon ? <Icon height={15} width={16}/> : null}
                        
                                        {row[column.accessor]}
                                    </td>
                                    );
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}

export default Table;
