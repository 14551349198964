import React, { useState, useRef, useEffect } from 'react';
import ApiService from '../../service/ApiService';
import { ReactComponent as SeePass } from '../../svg/eye.svg';
import { ReactComponent as HidePass } from '../../svg/not-eye.svg';
import './InstitutionForm.css';
import { useTranslation } from 'react-i18next';

const InstitutionForm = ({institutionId, navigate, checkUserSession}) => {
    const { t } = useTranslation('common');
    const [error, setError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [hidePass, setHidePass] = useState(true);
    const [hidePassRepeat, setHidePassRepeat] = useState(true);
    const form = useRef();
    const emailRef = useRef();
    const passwordRef = useRef();
    const passwordRepeatRef = useRef();
    const erakundeaRef = useRef();
    const cifRef = useRef();
    const helbideaRef = useRef();
    const cpRef = useRef();
    const herriaRef = useRef();
    const provinciaRef = useRef();
    const paisRef = useRef();
    const telefonoRef = useRef();
    const contactoRef = useRef();
    const usersRef = useRef();
    const logoRef = useRef();
    const languageRef = useRef();
    const pdfTextRef = useRef();
    const [logoUrl, setLogoUrl] = useState(null);
    const apiService = new ApiService();
    const [isAdmin, setIsAdmin] = useState(false);

    useEffect(() => {
        if (institutionId) {
            const filter = {
                limit: 10,
                skip: 0,
                where: {
                    id: ["id", parseInt(institutionId), "="]
                },
                order: "id DESC"
            };
            const encodedFilter = encodeURIComponent(JSON.stringify(filter));
            const url = `users.php?filter=${encodedFilter}`;
            // Get institution data
            apiService.get(url)
                .then(response => {
                    console.log(response);
                    if (!response) {
                        setError(true);
                        setErrorMessage('Operación no autorizada.');
                    }
                    if (response.success === false) {
                        setError(true);
                        setErrorMessage("Error al obtener la institución");
                    }
                    // Set institution data
                    erakundeaRef.current.value = response.data[0].erakundea;
                    emailRef.current.value = response.data[0].emaila;
                    cifRef.current.value = response.data[0].cif;
                    helbideaRef.current.value = response.data[0].helbidea;
                    cpRef.current.value = response.data[0].cp;
                    herriaRef.current.value = response.data[0].herria;
                    provinciaRef.current.value = response.data[0].provincia;
                    paisRef.current.value = response.data[0].pais;
                    telefonoRef.current.value = response.data[0].telefono;
                    contactoRef.current.value = response.data[0].contacto;
                    usersRef.current.value = response.data[0].zenbat;
                    languageRef.current.value = response.data[0].language;
                    pdfTextRef.current.value = response.data[0].footerpdf;
                    console.log("logo: " + response.data[0].logo);
                    setLogoUrl(response.data[0].logo || null);

                    const labels = form.current.querySelectorAll('label');
                    labels.forEach(label => {
                        label.classList.add('active');
                    });
                })
                .catch(error => {
                    setError(true);
                    setErrorMessage('Ha ocurrido un error al obtener la institución, inténtalo más tarde.');
                    console.error(error);
                });

                
        }

        checkUserSession().then(userInfo => {
            if(userInfo.admin) {
                setIsAdmin(true);
            }
        
        });
    }, [navigate, checkUserSession, institutionId]);

    const convertFileToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);

        if (passwordRef.current.value !== passwordRepeatRef.current.value) {
            setError(true);
            setErrorMessage('Las contraseñas no coinciden');
            setLoading(false);
            return;
        }

        // const formData = new FormData();
        // formData.append('emaila', emailRef.current.value);
        // formData.append('pasahitza', passwordRef.current.value);
        // formData.append('erakundea', erakundeaRef.current.value);
        // formData.append('cif', cifRef.current.value);
        // formData.append('helbidea', helbideaRef.current.value);
        // formData.append('cp', cpRef.current.value);
        // formData.append('herria', herriaRef.current.value);
        // formData.append('provincia', provinciaRef.current.value);
        // formData.append('pais', paisRef.current.value);
        // formData.append('telefono', telefonoRef.current.value);
        // formData.append('contacto', contactoRef.current.value);
        // formData.append('zenbat', usersRef.current.value);
        // formData.append('roll', 'institucion');
        // formData.append('idInstitucion', null);
        // formData.append('activo', 1);
    
        // if (logoRef.current.files[0]) {
        //     formData.append('logo', logoRef.current.files[0]);
        // }

        console.log("logo" + logoRef.current.files[0]);
        let logoBase64;
        if (logoRef.current.files[0]) {
            logoBase64 = await convertFileToBase64(logoRef.current.files[0]);
        }else {
            logoBase64 = null;
        }

        //send data in json format
        const formData = {
            emaila: emailRef.current.value,
            pasahitza: passwordRef.current.value,
            erakundea: erakundeaRef.current.value,
            cif: cifRef.current.value,
            helbidea: helbideaRef.current.value,
            cp: cpRef.current.value,
            herria: herriaRef.current.value,
            provincia: provinciaRef.current.value,
            pais: paisRef.current.value,
            telefono: telefonoRef.current.value,
            contacto: contactoRef.current.value,
            zenbat: usersRef.current.value,
            roll: 'institucion',
            idInstitucion: null,
            activo: 1,
            language: languageRef.current.value,
            pdfText: pdfTextRef.current.value,
            logo: logoBase64
        };
        if(institutionId) {
            formData.id = institutionId;
        }
        const options = {
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
        };
                
        if (institutionId) {
            // Update institution
            apiService.put(`users.php`, formData, options)
                .then(response => {
                    if(!response.success) {
                        setError(true);
                        setErrorMessage(t('app.form.editError'));
                    }else {
                        setError(false);
                        setLoading(false);
                        setSuccess(true);   
                    }
                   

                    setTimeout(() => {
                        navigate('#informes');
                    }, 1000);
                })
                .catch(error => {
                    setError(true);
                    setErrorMessage(t('app.form.editError'));
                    console.error(error);
                    setLoading(false);
                });
        } else {
            apiService.post('users.php', formData, options)
            .then(response => {
                console.log(response);
                if(!response.success) {
                    setError(true);
                    setErrorMessage(t('app.form.createError'));
                }else {
                    setError(false);
                    setLoading(false);
                    setSuccess(true);   
                }

                setTimeout(() => {
                    navigate('#informes');
                }, 1000);
            })
            .catch(error => {
                setError(true);
                setErrorMessage(t('app.form.createError'));
                console.error(error);
                setLoading(false);
            });
        }
    };

    const handleFocusLabel = (e) => {
        const label = e.target.previousElementSibling;
        label.classList.add('active');
    }

    const handleBlurLabel = (e) => {
        const label = e.target.previousElementSibling;
        if (e.target.value === '') {
            label.classList.remove('active');
        }
    }

    const handlePass = (value) => {
        setHidePass(value);
        if (value) {
            passwordRef.current.type = 'password';
        } else {
            passwordRef.current.type = 'text';
        }
    }

    const handleRepeatPass = (value) => {
        setHidePassRepeat(value);
        if (value) {
            passwordRepeatRef.current.type = 'password';
        } else {
            passwordRepeatRef.current.type = 'text';
        }
    }

    return (
        <div>
            <h4 className='col-12 ms-2'>{institutionId ? t('app.form.editInstitution'): t('app.form.newInstitution')}</h4>
            <p className='col-12 fw-semibold ms-2'>{institutionId ? t('app.form.editSubtitle') : t('app.form.newSubtitle')}</p>
            <form className='row landing-form px-0 mx-0 mt-4' ref={form} onSubmit={handleSubmit}>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.institutionName')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={erakundeaRef} type='text' className='form-control' placeholder='Introduzca la entidad' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.email')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={emailRef} type='email' className='form-control' placeholder='Introduzca su email' required />
                </div>
                <div className='input-group col-6 mb-3 relative'>
                    <label>{t('app.form.password')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={passwordRef} type='password' className='form-control' placeholder='Introduzca su contraseña' />
                    {hidePass ? <HidePass className='icon-pass' onClick={() => handlePass(false)} /> : <SeePass className='icon-pass' onClick={() => handlePass(true)} />}
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.passwordRepeat')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={passwordRepeatRef} type='password' className='form-control' placeholder='Repetir contraseña' />
                    {hidePassRepeat ? <HidePass className='icon-pass' onClick={() => handleRepeatPass(false)} /> : <SeePass className='icon-pass' onClick={() => handleRepeatPass(true)} />}
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.cif')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={cifRef} type='text' className='form-control' placeholder='Introduzca el CIF' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.province')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={provinciaRef} type='text' className='form-control' placeholder='Introduzca la provincia' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.city')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={herriaRef} type='text' className='form-control' placeholder='Introduzca la ciudad' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.zip')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={cpRef} type='text' className='form-control' placeholder='Introduzca el código postal' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.address')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={helbideaRef} type='text' className='form-control' placeholder='Introduzca la dirección' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.country')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={paisRef} type='text' className='form-control' placeholder='Introduzca el país' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.phone')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={telefonoRef} type='text' className='form-control' placeholder='Introduzca el teléfono' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label>{t('app.form.contact')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} ref={contactoRef} type='text' className='form-control' placeholder='Introduzca el contacto' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <label className='active z-5'>Logo</label>
                    <input ref={logoRef} type='file' className='form-control' placeholder='Introduzca el logo' />
                    
                </div>
                {institutionId && logoUrl &&
                    <div className='input-group col-6 mb-3'><img src={`https://patiaclient.fidenet.net/logos/${logoUrl}`}  alt='logo institution' className='logo-img' /></div>}
                <div className='input-group col-6 mb-3'>
                    <label class="active">{t('app.customPDF.textPDF')}</label>
                    <textarea ref={pdfTextRef} className='form-control mt-0' placeholder='Introduzca el texto del pdf' onFocus={handleFocusLabel} onBlur={handleBlurLabel}></textarea>
                </div>
                <div className={`input-group col-6 mb-3 ${!isAdmin ? 'd-none' : ''}`}>
                    <label>{t('app.form.limitUsers')}</label>
                    <input onBlur={handleBlurLabel} onFocus={handleFocusLabel} style={{fontSize: "15px"}} ref={usersRef} type='number' className='form-control' placeholder='Introduzca el número de usuarios permitidos' required />
                </div>
                <div className='input-group col-6 mb-3'>
                    <select className='form-control' ref={languageRef}>
                        {!institutionId && <option value="" disabled selected>{t('app.form.language')}</option>}
                        <option value='es'>{t('app.form.es')}</option>
                        <option value='en'>{t('app.form.en')}</option>
                    </select>
                </div>
                <div className='input-group col-6 mb-3'></div>
                <div className='input-group col-12 mt-2'>
                    <button className='btn button-landing effect effect-1 btn-terciary' type='submit'>
                        {institutionId ? t('app.form.save') : t('app.form.create')} {loading && <div className="loader loader-form"></div>}
                    </button>
                </div>
                {error && <p className="patia-error mt-2">{errorMessage}</p>}
                {success && <p className="patia-success mt-2">{institutionId ? t('app.form.editSuccess') : t('app.form.createSuccess')}</p>}
            </form>
        </div>
    );
};

export default InstitutionForm;